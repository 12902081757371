import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { dashboard } from '../../util';

import FindDirectionsBoxResults from '../../components/FindDirectionsBox/Results';
import BingMap from '../../components/BingMap';

function SearchResult(props) {
	return (
		<div>
			<div
				// style={
				// 	props.showBg
				// 		? props.bg
				// 			? { backgroundImage: `url('${props.bg}')` }
				// 			: {
				// 					backgroundImage: `url('${dashboard}/background')`,
				// 			  }
				// 		: null
				// }
				className={`toolbar search-page${props.showBg ? ' bg' : ''}${
					props.darkMode ? ' dark' : ''
				}`}
			>
				<BingMap id={'map'} options={{ zoom: 11 }} />
				<FindDirectionsBoxResults />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {})(SearchResult);
