import React, { useEffect, useState } from 'react';

import { HashRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import Categories from './components/Categories';

import Header from './components/Header';
import Footer from './components/Footer';
import Keep from './components/Keep';

import Home from './pages/Home/';
import Search from './pages/Search/';
import SearchResult from './pages/Search/Result';
import ListingsPage from './pages/Listings/';
import CategoriesPage from './pages/Categories/';

import { createAppToken, updateCategories } from './actions/AppActions';
import { setPreferencesInit } from './actions/PreferencesActions';

import './App.scss';
import { connect } from 'react-redux';

function App( {
	preferences,
	setPreferencesInit,
	token,
	createAppToken,
	updateCategories,
	categoriesVersion,
} ) {
	const [display, setDisplay] = useState( localStorage.getItem( 'display_keep_it1' ) || '' );

	useEffect( () => {
		if( !categoriesVersion ) {
			updateCategories();
		}
	}, [] );

	useEffect( () => {
		// localStorage.setItem('uid', 'cef954499dcd2d2d93e7f4801cf568ae');
		if( display !== '' && !preferences ) {
			setPreferencesInit( true );
		}
	}, [display] );

	useEffect( () => {
		if( token == '' ) {
			createAppToken();
		}
	}, [token] );

	return (
		<Router>
			<Header />
			{/* <Categories selfDisplay={display} /> */}
			<Switch>
				<Route path="/search/:from/:to">
					<SearchResult />
				</Route>
				<Route path="/search">
					<Search />
				</Route>

				<Route path="/listings/:id">
					<ListingsPage />
				</Route>
				<Route path="/categories">
					<CategoriesPage />
				</Route>
				{/* <Route path="/">{display === '' && !preferences ? <Search /> : <Home />}</Route> */}
				<Route path="/">
					<Search />
				</Route>
			</Switch>
			{/* <Footer /> */}
			{/* <div onClick={() => ( document.body.className = '' )} className="overlay"></div> */}
			{/* <Keep /> */}
		</Router>
	);
}

var mapStateToProps = ( state ) => {
	return {
		token: state.app.token,
		preferences: state.preferences.init,
		categoriesVersion: state.app.categoriesVersion,
	};
};

export default connect( mapStateToProps, {
	createAppToken,

	setPreferencesInit,
	updateCategories,
} )( App );
