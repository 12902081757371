import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';

import List from './List';

import { Icon } from 'react-icons-kit';
import { chevronCircleUp } from 'react-icons-kit/fa/chevronCircleUp';
import { chevronCircleDown } from 'react-icons-kit/fa/chevronCircleDown';
import BingMap from '../BingMap';

import { toggleTopBarCollapse } from '../../actions/TopBarActions';

function Categories({ collapsed, toggleTopBarCollapse, showBg, bg, darkMode, preferences }) {
	const location = useLocation();

	if (location.pathname.includes('/search') || location.pathname.includes('single/') || !preferences) {
		return null;
	}

	return (
		<section
			style={
				showBg
					? bg
						? { backgroundImage: `url('${bg}')` }
						: { backgroundImage: `url('../../images/bg.jpg)` }
					: null
			}
			className={`categories${showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}${
				collapsed ? ' collapsed' : ''
			}`}
		>
			<BingMap id={'map'} options={{ zoom: 11 }} />
			<span
				className="collapse-icon"
				onClick={() => {
					toggleTopBarCollapse('user');
				}}
			>
				{collapsed ? (
					<Icon size={40} icon={chevronCircleDown} />
				) : (
					<Icon size={40} icon={chevronCircleUp} />
				)}
			</span>
			<div className="container">
				<List favorites={true} />
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		showBg: state.settings.options.background.visible,
		preferences: state.preferences.init,
		collapsed: state.topBar.collapsed,
		bg: state.settings.backgroundURL,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {
	toggleTopBarCollapse,
})(Categories);
