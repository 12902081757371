import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

// Bing request
function getSuggestions(query, apiKey, location, setSuggestions) {
    axios
        .get("https://dev.virtualearth.net/REST/v1/Autosuggest", {
            params: {
                query: query,
                userLocation: location
                    ? location.lat + "," + location.lng + ",5000"
                    : "",
                key: apiKey,
                maxResults: 5,
            },
        })
        .then((res) => {
            const results = [];
            if (
                res.data.resourceSets &&
                res.data.resourceSets.length > 0 &&
                res.data.resourceSets[0].resources.length > 0 &&
                res.data.resourceSets[0].resources[0].value.length > 0
            ) {
                for (let i in res.data.resourceSets[0].resources[0].value) {
                    const names = [];
                    if (res.data.resourceSets[0].resources[0].value[i].name)
                        names.push(
                            res.data.resourceSets[0].resources[0].value[i].name
                        );
                    if (
                        res.data.resourceSets[0].resources[0].value[i].address
                            .formattedAddress
                    )
                        names.push(
                            res.data.resourceSets[0].resources[0].value[i]
                                .address.formattedAddress
                        );
                    if (
                        res.data.resourceSets[0].resources[0].value[i].address
                            .countryRegion
                    )
                        names.push(
                            res.data.resourceSets[0].resources[0].value[i]
                                .address.countryRegion
                        );
                    results.push({
                        data: res.data.resourceSets[0].resources[0].value[i],
                        label: names.join(", "),
                        value: names.join(", "),
                    });
                }
                setSuggestions(results);
            }
        });
}

// Menu component
function SuggestionsList({
    input,
    index,
    query,
    apiKey,
    location,
    container_id,
    isFocus,
    onSelect,
}) {
    let typeDelayTimeout,
        position,
        items = [];

    let [suggestions, setSuggestions] = useState([]);
    let [display, setDisplay] = useState(false);

    // Show/hide suggestion menu on input focus/blur
    useEffect(() => {
        setDisplay(isFocus);
    }, [isFocus]);

    // Item click callback
    const selectItem = function (i) {
        onSelect(suggestions[i].value);
    };

    // This effect will run everytime query changes, query being the input value
    // this will trigger the bing query 500 ms after the last change, if a new change
    // comes in before 500 ms, it will clear and wait 500 ms until no more changes
    useEffect(() => {
        if (query && query.length > 0 && display) {
            if (typeDelayTimeout) {
                clearTimeout(typeDelayTimeout);
            }
            typeDelayTimeout = setTimeout(() => {
                getSuggestions(query, apiKey, location, setSuggestions);
            }, 500);
        }
    }, [query]);

    // Set the menu position below the input
    if (input.current) {
        position = input.current.getBoundingClientRect();
        position.y = position.y + position.height;
    }

    // Render the menu list
    if (suggestions.length) {
        items = suggestions.map((item, i) => {
            // Get address parts
            let parts = String(item.value).split(",");
            // Bold the matches
            let newLabel = String(parts[0]).replace(
                new RegExp(query.trim(), "gi"),
                "<strong>$&</strong>"
            );
            // If no matches, bold the first part
            if (newLabel.indexOf("<strong>") === -1)
                newLabel = "<strong>" + parts[0] + "</strong>";
            // Wrap in the final element
            let newText =
                '<span class="ac-place"><span class="ac-place-first">' +
                newLabel +
                "</span>";
            // Join the rest of the parts
            if (parts.length > 1) {
                parts.splice(0, 1);
                newText += ", " + parts.join(", ");
            }
            // Close the wrapper
            newText += "</span>";

            // Add to the list
            return (
                <li
                    key={i}
                    className="ui-menu-item"
                    dangerouslySetInnerHTML={{
                        __html: newText,
                    }}
                    onClick={(e) => {
                        selectItem(i);
                    }}
                ></li>
            );
        });
    }

    return suggestions.length && display
        ? ReactDOM.createPortal(
              <ul
                  id={`ui-id-` + index}
                  style={{
                      top: position ? position.y : 0,
                      left: position ? position.x : 0,
                      width: position ? position.width : 300,
                  }}
                  className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content"
              >
                  {" "}
                  {items}{" "}
              </ul>,
              document.getElementById(container_id)
          )
        : "";
}

// Input component
function AutoComplete({
    className,
    apiKey,
    location,
    index,
    onLocationSet,
    onLocationSelect,
    defaultValue,
    ...props
}) {
    let container_id;

    let [value, setValue] = useState("");
    let [isFocus, setIsFocus] = useState(false);

    let inputEl = useRef(null);

    // set default value
    useEffect(() => {
        setValue(defaultValue);
        onLocationSet && onLocationSet(defaultValue);
    }, [defaultValue]);

    // get input value on react
    const onChange = function (e) {
        setValue(e.target.value);
        onLocationSet && onLocationSet(e.target.value);
    };

    // let SuggestionList know weather the input is focused
    const onFocus = function (e) {
        setIsFocus(true);
        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    // let SuggestionList know weather the input is blurred
    // small delay in case the blur comes from an item list
    const onBlur = function (e) {
        setTimeout(() => {
            setIsFocus(false);
        }, 200);

        if (props.onBlur) {
            props.onBlur(e);
        }
    };

    // SuggestionList item selected callback
    const onSelect = function (newValue) {
        setValue(newValue);
        onLocationSet && onLocationSet(newValue);
        onLocationSelect && onLocationSelect(newValue);
    };

    // Set the container id and add the portal element parent
    container_id = "ui-autocomplete-" + index;
    if (!document.getElementById(container_id)) {
        let container = document.createElement("div");
        container.id = container_id;
        document.body.appendChild(container);
    }

    return (
        <>
            <input
                {...props}
                type="text"
                className={`form-control ui-autocomplete-input ${className}`}
                autoComplete="off"
                onChange={onChange}
                ref={inputEl}
                // onFocus={onFocus}
                // onBlur={onBlur}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
            />{" "}
            <SuggestionsList
                input={inputEl}
                index={index}
                query={value}
                location={location}
                apiKey={apiKey}
                container_id={container_id}
                isFocus={isFocus}
                onSelect={onSelect}
            />{" "}
        </>
    );
}

AutoComplete.defaultProps = {
    defaultValue: "",
};

export default AutoComplete;
