import React, { useState } from 'react';

import AutoComplete from '../tripod-components/autocomplete';
import pin from '../../images/pin.png';
import switchPin from '../../images/switch.png';

import './style.scss';

function FindDirectionsBox({ onSearch }) {
	// State
	const [bingKey] = useState('AshH7R2MJYwFD5t64YhV5DAT-ttMsPhNoIPQYZfpB38kUDgMqXWcMXyeMnTj3O16');

	const handleSwitch = () => {
		let origin = document.getElementById('js-origin-geoip').value;
		let dest = document.getElementById('js-destination-geoip').value;
		document.getElementById('js-origin-geoip').value = dest;
		document.getElementById('js-destination-geoip').value = origin;
	};

	return (
		<div className="find-directions-box pt-4">
			<h2 className="text-center mb-3">Find Directions</h2>
			<form className="form" onSubmit={onSearch}>
				<div className="fields">
					<img className="pin" src={pin} />
					<div className="auto-complete mb-3">
						<AutoComplete
							index="4"
							apiKey={bingKey}
							id="js-origin-geoip"
							className="search"
							placeholder="Enter Starting Point"
						/>
					</div>
					<div className="auto-complete">
						<AutoComplete
							index="5"
							apiKey={bingKey}
							id="js-destination-geoip"
							className="search"
							placeholder="Enter Destination"
						/>
					</div>
					<img onClick={handleSwitch} className="switch" src={switchPin} />
				</div>
				<button type="submit" className="btn btn-primary mt-3">
					Get Directions
				</button>
			</form>
		</div>
	);
}

export default FindDirectionsBox;
