import React, { useState } from 'react';
import { connect } from 'react-redux';

import AutoComplete from '../../tripod-components/autocomplete';
import { geocode } from '../../tripod-components/util/bing';

import { setUnits, setUserLocation } from '../../../actions/WeatherActions';
import SettingItem from '../../SettingItem';

import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/ionicons/edit';
import { close } from 'react-icons-kit/ionicons/close';
import './style.scss';

function Weather({
	showWeather,
	units,
	setUnits,
	city,
	setUserLocation
}) {
	// State
	// const [userLocation, setUserLocation] = useState(null);
	const [bingKey] = useState(
		'AshH7R2MJYwFD5t64YhV5DAT-ttMsPhNoIPQYZfpB38kUDgMqXWcMXyeMnTj3O16'
	);
	const [isSearching, setIsSearching] = useState(false);

	const handleLocationSelect = function(data) {
		geocode(data, bingKey).then((data) => {
			setUserLocation(data.city, data.state, { lat: data.point[0], lng: data.point[1] })
			setIsSearching(false);
		});
	};

	if (!showWeather) 
		return null;

	return (
		<div className="weather-options">
			<div className="full-city">
				{isSearching ? (
					<div className="auto-complete">
						<AutoComplete
							index="3"
							// location={userLocation}
							apiKey={bingKey}
							id="js-destination-geoip"
							className="search"
							placeholder="Search City"
							onLocationSet={(data) => {}}
							onLocationSelect={handleLocationSelect}
						/>
						<Icon onClick={() => setIsSearching(false)} size="14" icon={close} />
					</div>
				) : (
					<label>
						{city}
						<Icon onClick={() => setIsSearching(true)} size="14" icon={edit} />
					</label>
				)}
			</div>
			<SettingItem
				name={'Metric Units'}
				onCheckBoxChange={() => {
					setUnits(units === 'metric' ? 'imperial' : 'metric');
				}}
				isChecked={units === 'metric' ? true : false}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		units: state.weather.units,
		showWeather: state.settings.options.weather.visible,
		city: state.weather.city,
	};
};

export default connect(mapStateToProps, { 
	setUnits, 
	setUserLocation
})(Weather);
