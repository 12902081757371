import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { link } from 'react-icons-kit/ionicons/link';

import { setOption } from '../../actions/SettingsActions';

import SettingItem from '../SettingItem';

import './style.scss';

function Options({ settings, setOption }) {
	return (
		<ul className="text-left list-unstyled">
			{settings.map((setting, index) => {
				return (
					<li key={index}>
						<SettingItem
							name={setting.name}
							onCheckBoxChange={() => {
								setOption({
									key: setting.id,
									value: !setting.visible,
								});
							}}
							isChecked={setting.visible}
						/>
					</li>
				);
			})}
			<li>
				<div className="setting-item clearfix">
					<Link onClick={() => (document.body.className = '')} to={'/search'}>
						<label>
							Find Directions
							<Icon
								size="25"
								style={{ color: '#888888', marginRight: 5 }}
								icon={link}
							/>
						</label>
					</Link>
				</div>
			</li>
		</ul>
	);
}

const mapStateToProps = (state) => {
	return {
		settings: Object.keys(state.settings.options).map((k) => {
			return { ...state.settings.options[k], id: k };
		}),
	};
};

export default connect(mapStateToProps, { setOption })(Options);
