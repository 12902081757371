import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Listings from '../../components/Listings';
import { selectCategory } from '../../actions/AppActions';
import { resetTopBarInteractions } from '../../actions/TopBarActions';

import './style.scss';

function ListingsPage({ resetTopBarInteractions, selectCategory }) {
	let { id } = useParams();

	useEffect(() => {
		resetTopBarInteractions('user');
	}, []);

	useEffect(() => {
		selectCategory(id);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="toolbar">
			<Listings />
		</div>
	);
}

export default connect(null, {
	selectCategory,
	resetTopBarInteractions,
})(ListingsPage);
