import {
	GET_CATEGORIES,
	GET_ALL_CATEGORIES,
	SELECT_CATEGORY,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
	SELECT_PROVIDER,
	GET_PROVIDERS,
	UPDATE_CATEGORIES,
} from './types';
import axios from 'axios';
import Cookies from 'js-cookie';

import { dashboard, mainSite } from '../util';

export const updateCategories = () => {
	return {
		type: UPDATE_CATEGORIES,
	};
};

export const getCategories = () => {
	return (dispatch) => {
		axios.get(dashboard + '/api/categories').then((res) => {
			dispatch({
				type: GET_CATEGORIES,
				payload: res.data,
			});
		});
	};
};

export const getAllCategories = () => {
	return (dispatch) => {
		axios.get(dashboard + '/api/categories/all').then((res) => {
			dispatch({
				type: GET_ALL_CATEGORIES,
				payload: res.data,
			});
		});
	};
};

export const getProviders = () => {
	return (dispatch) => {
		axios.get(dashboard + '/api/providers').then((res) => {
			dispatch({
				type: GET_PROVIDERS,
				payload: res.data,
			});
		});
	};
};

export const searchCategory = (value) => {
	return (dispatch, getState) => {
		dispatch({
			type: APP_SEARCH_RESULTS,
			payload: [],
		});

		// dispatch({
		// 	type: SELECT_PROVIDER,
		// 	payload: null,
		// });

		if (value !== 'favorites') {
			dispatch({
				type: SEARCHING_RESULTS,
				payload: true,
			});
			axios
				.get(dashboard + '/api/search', {
					params: {
						category: value.id,
						site: getState().app.selected_provider
							? getState().app.selected_provider
							: null,
					},
				})
				.then((res) => {
					dispatch({
						type: APP_SEARCH_RESULTS,
						payload: res.data,
					});
				});
		}
	};
};

export const loadMoreCategory = (value, page) => {
	return (dispatch, getState) => {
		dispatch({
			type: SEARCHING_MORE_RESULTS,
			payload: true,
		});
		axios
			.get(dashboard + '/api/search', {
				params: {
					category: value.id,
					site: getState().app.selected_provider
						? getState().app.selected_provider
						: null,
					page: page,
				},
			})
			.then((res) => {
				dispatch({
					type: APP_SEARCH_MORE_RESULTS,
					payload: res.data,
				});
			});
	};
};

export const searchResults = (query) => {
	return (dispatch, getState) => {
		dispatch({
			type: APP_SEARCH_RESULTS,
			payload: [],
		});
		dispatch({
			type: SEARCHING_RESULTS,
			payload: true,
		});
		axios
			.get(dashboard + '/api/search', {
				params: {
					query: query,
					site: getState().app.selected_provider
						? getState().app.selected_provider
						: null,
					category: getState().app.selected_category
						? getState().app.selected_category.id
						: null,
				},
			})
			.then((res) => {
				dispatch({
					type: APP_SEARCH_RESULTS,
					payload: res.data,
				});
			});
	};
};

export const loadMoreResults = (query, page) => {
	return (dispatch, getState) => {
		dispatch({
			type: SEARCHING_MORE_RESULTS,
			payload: true,
		});
		axios
			.get(dashboard + '/api/search', {
				params: {
					query: query,
					page: page,
					site: getState().app.selected_provider
						? getState().app.selected_provider
						: null,
					category: getState().app.selected_category
						? getState().app.selected_category.id
						: null,
				},
			})
			.then((res) => {
				dispatch({
					type: APP_SEARCH_MORE_RESULTS,
					payload: res.data,
				});
			});
	};
};

export const selectCategory = (value) => {
	return {
		type: SELECT_CATEGORY,
		payload: value,
	};
};

export const selectProvider = (value) => {
	return {
		type: SELECT_PROVIDER,
		payload: value,
	};
};

export const addFavorite = (route) => {
	return {
		type: ADD_FAVORITE,
		payload: route,
	};
};

export const getFavorites = () => {
	return (dispatch, getState) => {
		axios
			.get(dashboard + `/api/favorites`, {
				params: {
					uid: getState().app.token,
				},
			})
			.then((res) => {
				dispatch({
					type: ADD_FAVORITE,
					payload: res.data,
				});
			});
	};
};

// helper not action
export const getSingle = (id, callback) => {
	axios.get(dashboard + `/api/single/${id}`).then((res) => {
		callback(res.data);
	});
};

// helper not action
export const getReviews = (id, callback) => {
	axios.get(dashboard + `/api/single/${id}/reviews`).then((res) => {
		callback(res.data);
	});
};

// helper not action
export const getAutocompleteSuggestions = (query, callback) => {
	axios
		.get(dashboard + '/api/autocomplete', {
			params: {
				q: query,
			},
		})
		.then((res) => {
			callback(res.data);
		});
};

// Token is the only link between favorited directions
// It's used to connect the extension with the main site
export const createAppToken = () => {
	return (dispatch) => {
		if (Cookies.get('app_token')) {
			var tkn = Cookies.get('app_token');
			// Refresh cookie
			var TokenPixel = new Image();
			TokenPixel.src = `${mainSite}/tkn.php?tkn=${tkn}`;
			document.body.appendChild(TokenPixel);

			dispatch({
				type: SET_APP_TOKEN,
				payload: tkn,
			});

			dispatch(getFavorites());
		} else {
			var uid = localStorage.getItem('uid');
			// Refresh cookie
			var TokenPixel = new Image();
			TokenPixel.src = `${mainSite}/tkn.php?tkn=${uid}`;
			document.body.appendChild(TokenPixel);

			dispatch({
				type: SET_APP_TOKEN,
				payload: uid,
			});
		}
	};
};
