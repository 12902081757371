import React, { useCallback } from 'react';

import { Icon } from 'react-icons-kit';

import { androidArrowUp } from 'react-icons-kit/ionicons/androidArrowUp';
import { useDropzone } from 'react-dropzone';

import './style.scss';

function Upload({ bg, setBackground }) {
	const onDrop = useCallback(acceptedFiles => {
		saveFile(acceptedFiles[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop
	});

	const saveFile = file => {
		var reader = new FileReader();
		reader.onload = function(e) {
			var imageType = /^image\//;

			if (!imageType.test(file.type)) return;

			if (e.loaded > 1000000) {
				alert('Sorry!, Your image is too big');
				return;
			}

			setBackground(e.target.result);
		};
		reader.readAsDataURL(file);
	};

	return (
		<>
			<div
				{...getRootProps()}
				style={{ backgroundImage: `url('${bg}')` }}
				className={
					isDragActive ? 'drag-and-drop dragover' : 'drag-and-drop'
				}
			>
				<input
					accept="image/gif, image/jpeg, image/png"
					{...getInputProps()}
				/>

				<Icon size="15" icon={androidArrowUp} />
				<p>
					Drag an Image here <br /> to upload your own background
				</p>
			</div>
			<p>- Max Size 2MB -</p>
			<div className="select-file">
				<input
					accept="image/gif, image/jpeg, image/png"
					type="file"
					id="customBG"
					onChange={e => {
						saveFile(e.target.files[0]);
					}}
				/>
				<button className="btn btn-lg">Choose Image</button>
			</div>
		</>
	);
}

export default Upload;
