import React from 'react';

import './style.scss';

let CheckboxItem = function({ checked, onChange }) {
	return (
		<div className="custom-checkbox pull-right">
			<input onChange={onChange} checked={checked} type="checkbox" />
			<div className="checkbox-switch"></div>
		</div>
	);
};

export default CheckboxItem;
