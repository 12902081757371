import {
	GET_CATEGORIES,
	GET_ALL_CATEGORIES,
	SELECT_CATEGORY,
	GET_PROVIDERS,
	SELECT_PROVIDER,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
	UPDATE_CATEGORIES,
} from '../actions/types';

const INITIAL_STATE = {
	categories: [],
	allCategories: null,
	selected_category: null,
	providers: [],
	selected_provider: null,
	searching: false,
	results: [],
	hasMore: false,
	page: 1,
	loadingMore: false,
	favorites: [],
	favoriteIDs: [],
	token: '',
};

const checkFavoriteRoutes = (favorites, current) => {
	let currentExist = false;

	let newFavorites = favorites.filter((fav) => {
		let condition =
			fav.from.direction === current.from.direction &&
			fav.to.direction === current.to.direction;

		if (condition) currentExist = true;

		return !condition;
	});

	if (currentExist) {
		return newFavorites;
	} else {
		newFavorites.push(current);
		return newFavorites;
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};
		case GET_ALL_CATEGORIES:
			return {
				...state,
				allCategories: action.payload,
			};
		case SELECT_CATEGORY:
			return {
				...state,
				selected_category: action.payload,
			};
		case GET_PROVIDERS:
			return {
				...state,
				providers: action.payload,
			};
		case SELECT_PROVIDER:
			return {
				...state,
				selected_provider: action.payload,
			};
		case SEARCHING_RESULTS:
			return {
				...state,
				searching: true,
			};
		case SEARCHING_MORE_RESULTS:
			return {
				...state,
				loadingMore: true,
			};
		case APP_SEARCH_RESULTS:
			if (action.payload == undefined) {
				action.payload = [];
			}
			return {
				...state,
				searching: false,
				results: action.payload,
				hasMore: action.payload.length > 0,
				page: 1,
			};
		case APP_SEARCH_MORE_RESULTS:
			return {
				...state,
				loadingMore: false,
				results: [...state.results, ...action.payload],
				hasMore: action.payload.length > 0,
				page: state.page + 1,
			};
		case ADD_FAVORITE:
			return {
				...state,
				favorites: checkFavoriteRoutes(state.favorites, action.payload),
			};
		case SET_APP_TOKEN:
			return {
				...state,
				token: action.payload,
			};
		case UPDATE_CATEGORIES:
			return {
				...state,
				categories: INITIAL_STATE.categories,
				categoriesVersion: 1,
			};
		default:
			return state;
	}
};
