import { SET_OPTION, SET_BACKGROUND } from './types';

export const setOption = (value) => {
	return {
		type: SET_OPTION,
		payload: value,
	};
};

export const setBackground = (value) => {
	return (dispatch) => {
		getImageLightness(value, (brightness) => {
			dispatch({ type: SET_BACKGROUND, payload: { bg: value, darkMode: brightness < 100 } });
		});
	};
};

const getImageLightness = (imageSrc, callback) => {
	var img = document.createElement('img');
	img.src = imageSrc;
	img.style.display = 'none';
	document.body.appendChild(img);

	var colorSum = 0;

	img.onload = function () {
		// create canvas
		var canvas = document.createElement('canvas');
		canvas.width = this.width;
		canvas.height = this.height;

		var ctx = canvas.getContext('2d');
		ctx.drawImage(this, 0, 0);

		var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
		var data = imageData.data;
		var r, g, b, avg;

		for (var x = 0, len = data.length; x < len; x += 4) {
			r = data[x];
			g = data[x + 1];
			b = data[x + 2];

			avg = Math.floor((r + g + b) / 3);
			colorSum += avg;
		}

		var brightness = Math.floor(colorSum / (this.width * this.height));
		callback(brightness);
	};
};
