import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import { setPreferencesInit } from '../../actions/PreferencesActions';

function Category({ setPreferencesInit, cat, onClick, selected_category }) {
	const [selected, setSelected] = useState(false);

	// var hanldeClick = function () {
	// 	setSelected(true);
	// 	onClick(cat);
	// };

	useEffect(() => {
		if (selected_category) {
			var catid = cat.id ? cat.id : cat.ID;
			var selectedid = selected_category.id ? selected_category.id : (selected_category.ID ? selected_category.ID : selected_category);
			setSelected(catid == selectedid);
		} else {
			setSelected(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_category]);

	let url = `/listings/${cat.id ? cat.id : cat.ID}`;
	if ((cat.id && cat.id == 'search') || (cat.ID && cat.ID == 'search')) {
		url = `/search`;
	}
	if ((cat.id && cat.id == 'categories') || (cat.ID && cat.ID == 'categories')) {
		url = `/categories`;
	}

	let image = !!cat.PrimaryImage.default ? cat.PrimaryImage.default : cat.PrimaryImage;
	return (
		<Link
			onClick={() => {
				setPreferencesInit(true);
			}}
			to={url}
			className={selected ? 'category-item icon selected' : 'category-item icon'}
		>
			<img alt={Parser(cat.Category ? cat.Category : cat.name)} src={image} />
			<span>{Parser(cat.Category ? cat.Category : cat.name)}</span>
		</Link>
	);
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
	};
};

export default connect(mapStateToProps, {
	setPreferencesInit,
})(Category);
