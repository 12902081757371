import axios from "axios";

export function geocode(address, apiKey) {
    return new Promise((resolve, reject) => {
        axios
            .get("https://dev.virtualearth.net/REST/v1/Locations", {
                params: {
                    query: address,
                    maxResults: 1,
                    key: apiKey,
                },
            })
            .then((results) => {
                if (
                    !results.data.resourceSets ||
                    results.data.resourceSets.length == 0 ||
                    results.data.resourceSets[0].resources.length == 0
                ) {
                    reject(false);
                } else {
                    let point =
                        results.data.resourceSets[0].resources[0].point
                            .coordinates;
                    let city = "";
                    let state = "";
                    let country =
                        results.data.resourceSets[0].resources[0].address
                            .countryRegion;
                    if (
                        results.data.resourceSets[0].resources[0].address
                            .locality
                    ) {
                        city =
                            results.data.resourceSets[0].resources[0].address
                                .locality;
                    } else if (
                        results.data.resourceSets[0].resources[0].address
                            .adminDistrict2
                    ) {
                        city =
                            results.data.resourceSets[0].resources[0].address
                                .adminDistrict2;
                    }

                    if (results.data.resourceSets[0].resources[0].address.adminDistrict) {
                        state = results.data.resourceSets[0].resources[0].address.adminDistrict;
                    } else {
                        state = country;
                    }

                    resolve({
                        point,
                        city,
                        country,
                        state
                    });
                }
            })
            .catch(reject);
    });
}

export function route(start, end, apiKey) {
    return new Promise((resolve, reject) => {
        axios
            .get("https://dev.virtualearth.net/REST/V1/Routes/Driving", {
                params: {
                    o: "json",
                    "wp.0": start.join(","),
                    "wp.1": end.join(","),
                    key: apiKey,
                },
            })
            .then((results) => {
                if (
                    !results.data.resourceSets ||
                    results.data.resourceSets.length == 0 ||
                    results.data.resourceSets[0].resources.length == 0
                ) {
                    reject(false);
                } else {
                    resolve(
                        results.data.resourceSets[0].resources[0].routeLegs[0]
                            .itineraryItems
                    );
                }
            })
            .catch(reject);
    });
}
