import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Weather from '../Weather';
import Form from '../Form/';
import Time from '../Time';
import logo from '../../images/logo.png';
import './style.scss';

function Header(props) {
	const location = useLocation();
	return (
		<header className='justify-content-center'>
			<img alt="Online Maps App" className={'logo'} src={logo} />
			{/* <ul className="d-flex list-unstyled m-0">
				<li>
					<Form type="header" target="_blank" placeholder={'Search'} />
				</li>
			</ul> */}
			{/* {props.showTime && location.pathname !== '/search' && props.preferences === true && <Time />} */}
			{/* {props.showWeather && location.pathname !== '/search' && props.preferences === true && <Weather />} */}
		</header>
	);
}
const mapStateToProps = (state) => {
	return {
		showBg: state.settings.options.background.visible,
		showWeather: state.settings.options.weather.visible,
		showTime: state.settings.options.time.visible,
		preferences: state.preferences.init
	};
};

export default connect(mapStateToProps, {})(Header);
