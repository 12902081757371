import React, { useEffect } from 'react';

import './style.scss';

const BING_KEY = 'AshH7R2MJYwFD5t64YhV5DAT-ttMsPhNoIPQYZfpB38kUDgMqXWcMXyeMnTj3O16';
window.maps = {};
let defaultOptions = {
	credentials: BING_KEY,
	customMapStyle: {
		settings: {
			landColor: '#F3F2EF',
		},
		elements: {
			water: {
				fillColor: '#AADAFF',
			},
			road: {
				fillColor: '#fff',
				strokeColor: '#DDDFE3',
				labelColor: '#98A0A3',
				labelOutlineColor: 'transparent',
			},
			highway: {
				fillColor: '#FFF2AF',
				strokeColor: '#F9DB7E',
				labelColor: '#783B03',
				labelOutlineColor: '#FFFBE7',
			},
			vegetation: {
				fillColor: '#CEEECE',
			},
			indigenousPeoplesReserve: {
				fillColor: '#CEEECE',
			},
			countryRegion: {
				borderStrokeColor: '#aaa',
				borderOutlineColor: 'transparent',
			},
			controlledAccessHighway: {
				fillColor: '#FFF2AF',
				strokeColor: '#F9DB7E',
				labelColor: '#783B03',
				labelOutlineColor: '#FFFBE7',
			},
			majorRoad: {
				fillColor: '#FFF2AF',
				strokeColor: '#F9DB7E',
				labelColor: '#783B03',
				labelOutlineColor: '#FFFBE7',
			},
			tollRoad: {
				fillColor: '#FFF2AF',
				strokeColor: '#F9DB7E',
				labelColor: '#783B03',
				labelOutlineColor: '#FFFBE7',
			},
			area: {
				fillColor: '#F0F1F2',
			},
			building: {
				fillColor: '#F0F0F0',
				strokeColor: 'transparent',
			},
		},
		version: '1.0',
	},
	showBreadcrumb: false,
	showDashboard: false,
	showLocateMeButton: false,
	showScalebar: false,
	disableStreetside: true,
};

function BingMap({ id, options }) {
	useEffect(() => {
		if (!window.Microsoft) {
			const scriptTag = document.createElement('script');
			scriptTag.setAttribute('type', 'text/javascript');
			scriptTag.setAttribute(
				'src',
				'https://www.bing.com/api/maps/mapcontrol?callback=makeMap'
			);
			scriptTag.async = true;
			scriptTag.defer = true;

			document.body.appendChild(scriptTag);
			window.makeMap = () => {
				window.maps[`#${id}`] = new window.Microsoft.Maps.Map(`#${id}`, {
					mapTypeId: window.Microsoft.Maps.MapTypeId.road,
					...defaultOptions,
					...options,
				});
			};
		} else {
			window.maps[`#${id}`] = new window.Microsoft.Maps.Map(`#${id}`, {
				mapTypeId: window.Microsoft.Maps.MapTypeId.road,
				...defaultOptions,
				...options,
			});
		}
	}, []);

	return <div id={id} className="bing-map"></div>;
}

export default BingMap;
