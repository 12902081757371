import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Category from './Category.js';
import Favorite from './Favorite.js';

import { getCategories } from '../../actions/AppActions';

function Categories({ categories, getCategories, ...props }) {
	const [mainCategories, setMainCategories] = useState([]);
	const location = useLocation();


	return (
		<>
			<div className="owl-theme-category d-flex justify-content-center">
				{props.favorites && <Favorite />}

				{location.pathname !== '/search' && props.preferences === true && (
					<Category
						cat={{
							Category: 'Search <br>Directions',
							ID: 'search',
							PrimaryImage: require('../../images/icons/search.png'),
						}}
					/>
				)}
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		categories: state.app.categories,
		preferences: state.preferences.init,
	};
};

export default connect(mapStateToProps, {
	getCategories,
})(Categories);
