import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import AmazonAds from '../../components/Listings/AmazonAds';

import './style.scss';

import { selectCategory, getAllCategories } from '../../actions/AppActions';
import { resetTopBarInteractions } from '../../actions/TopBarActions';

function CategoriesPage({
	resetTopBarInteractions,
	getAllCategories,
	selectCategory,
	categories,
	brands,
}) {
	let location = useLocation();

	useEffect(() => {
		resetTopBarInteractions('user');
	}, []);

	const RenderItems = () => {
		if (!categories || categories.length == 0) return '';

		return Object.keys(categories).map(function (key, i) {
			return (
				<div key={i} className="alphabet">
					<h2>{key}</h2>
					<div className="d-flex flex-wrap">
						{categories[key].map((item, y) => {
							return (
								<p key={y}>
									<Link to={`listings/${item.id}`}>{item.name}</Link>
								</p>
							);
						})}
					</div>
				</div>
			);
		});
	};

	return (
		<div className="toolbar">
			<div className="container listings">
				{/* <AmazonAds /> */}
				<RenderItems />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		categories: state.app.allCategories,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	getAllCategories,
	resetTopBarInteractions,
})(CategoriesPage);
