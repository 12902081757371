import {
	ADD_TODO,
	REMOVE_TODO,
	MODIFY_TODO_TEXT,
    MODIFY_TODO_STATE,
    MODIFY_NOTE
} from './types';

export const addTodo = value => {
	return {
		type: ADD_TODO,
		payload: value
	};
};

export const removeTodo = id => {
	return {
		type: REMOVE_TODO,
		payload: id
	};
};

export const changeTodoState = id => {
	return {
		type: MODIFY_TODO_STATE,
		payload: id
	};
};

export const changeTodoText = (id, text) => {
	return {
		type: MODIFY_TODO_TEXT,
		payload: {id, text}
	};
};

export const changeNoteText = value => {
	return {
		type: MODIFY_NOTE,
		payload: value
	};
};