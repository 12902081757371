import { SET_QUOTE, ADD_SHORTCUT, REMOVE_SHORTCUT, SET_REMOTE_SHORTCUTS } from './types';

import { dashboard } from '../util';

const API_URL = 'https://api.quotable.io/random';
const SHORTCUTS_URL = dashboard + '/shortcuts';

export const getQuote = () => {
	return (dispatch) => {
		fetch(API_URL)
			.then((response) => response.json())
			.then((response) => {
				let quote = `${response.content} - by ${response.author}`;
				if (quote.length > 100) {
					dispatch(getQuote());
				} else {
					dispatch({ type: SET_QUOTE, payload: quote });
				}
			})
			.catch(() => {});
	};
};

export const addShortCut = (data) => {
	return {
		type: ADD_SHORTCUT,
		payload: data,
	};
};

export const removeShortCut = (key) => {
	return {
		type: REMOVE_SHORTCUT,
		payload: key,
	};
};

export const getRemoteShortcuts = () => {
	return (dispatch) => {
		fetch(SHORTCUTS_URL, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: SET_REMOTE_SHORTCUTS,
					payload: response,
				});
			});
	};
};
