import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Icon } from 'react-icons-kit';
import { androidCheckboxOutline } from 'react-icons-kit/ionicons/androidCheckboxOutline';
import { clipboard } from 'react-icons-kit/ionicons/clipboard';
import { plusRound } from 'react-icons-kit/ionicons/plusRound';
import TextareaAutosize from 'react-autosize-textarea';

import {
	addTodo,
	removeTodo,
	changeTodoState,
	changeTodoText,
	changeNoteText
} from '../../actions/TodosActions';

import TodoList from './todos';

import './style.scss';

function Todos(props) {
	const [activeTab, setActiveTabState] = useState(0);

	return (
		<div className="todos">
			<ul className="tabs nav nav-tabs">
				<li className="nav-item">
					<button
						className={
							activeTab === 0 ? 'nav-link active' : 'nav-link'
						}
						onClick={() => setActiveTabState(0)}
					>
						<Icon size="15" icon={androidCheckboxOutline} />
						Todos
					</button>
				</li>
				<li className="nav-item">
					<button
						className={
							activeTab === 1 ? 'nav-link active' : 'nav-link'
						}
						onClick={() => setActiveTabState(1)}
					>
						<Icon size="15" icon={clipboard} />
						Notes
					</button>
				</li>
			</ul>

			<div className="tab-content" id="nav-tabContent">
				<div
					className={[
						'tab-pane fade show',
						activeTab === 0 ? 'active' : null
					].join(' ')}
				>
					<ul className="todos-list list-unstyled">
						{props.todos.length > 0 && (
							<TodoList
								onDeleteItem={id => {
									props.removeTodo(id);
								}}
								onChangeItemState={id => {
									props.changeTodoState(id);
								}}
								onChangeItemText={(id, text) => {
									props.changeTodoText(id, text);
								}}
								items={props.todos}
							/>
						)}

						{props.todos.length === 0 && (
							<li className="empty">Nothing to do</li>
						)}
					</ul>
					<div className="actions">
						<button onClick={() => props.addTodo()}>
							<Icon size="15" icon={plusRound} />
							New todo
						</button>
					</div>
				</div>
				<div
					className={[
						'tab-pane fade show',
						activeTab === 1 ? 'active' : null
					].join(' ')}
				>
					<div className="notes-content">
						<TextareaAutosize
							rows={1}
							placeholder="Write here..."
							value={props.note}
							onChange={e => {
								props.changeNoteText(e.target.value);
							}}
						></TextareaAutosize>
					</div>
				</div>
			</div>
		</div>
	);
}
const mapStateToProps = state => {
	return {
		todos: Object.keys(state.todos.todos).map(k => {
			return { ...state.todos.todos[k], id: k };
		}),
		note: state.todos.note
	};
};

export default connect(mapStateToProps, {
	addTodo,
	removeTodo,
	changeTodoState,
	changeTodoText,
	changeNoteText
})(Todos);
