import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Listing from './listing.js';

import './style.scss';

function Listings({ selected_category, favorites }) {
	return (
		<div className="container listings">
			<div className="row py-4">
				<div className="col d-flex align-items-center">
					<Link className="exit" to="/">
						&times;
					</Link>
					<p className="m-0">My Favorites</p>
				</div>
			</div>
			{selected_category === 'favorites' &&
				(favorites.length ? (
					<>
						{/* <div className="row pb-4"><AmazonAds /></div> */}
						<div className="row">
							{favorites.map((result, idx) => (
								<div key={idx} className="col-lg-3 col-md-4 col-sm-6">
									<Listing object={result} />
								</div>
							))}
						</div>
					</>
				) : (
					<div className="not-found favorites">
						No Favorites Found
						<br />
						Make A Search to Start
					</div>
				))}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
		favorites: state.app.favorites,
	};
};

export default connect(mapStateToProps, {})(Listings);
