import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';

import FindDirectionsBox from '../../components/FindDirectionsBox';
import BingMap from '../../components/BingMap';

import { setPreferencesInit } from '../../actions/PreferencesActions';
import { resetTopBarInteractions } from '../../actions/TopBarActions';

import { dashboard } from '../../util';

import './style.scss';

function Search( { resetTopBarInteractions, setPreferencesInit, preferences, ...props } ) {
	const history = useHistory();

	useEffect( () => {
		resetTopBarInteractions( 'user' );
	}, [] );

	useEffect( () => {
		if( !preferences ) {
			// first run
			const first_search = localStorage.getItem( 'first_search' );
			if( first_search ) {
				// Found cookie search
				setPreferencesInit( true );
			}
		}
	}, [preferences] );

	var handleExitClick = function( e ) {
		setPreferencesInit( true );
	};

	// Load Search Results
	var handleSearchSubmit = function( e ) {
		e.preventDefault();
		setPreferencesInit( true );
		let origin = document.getElementById( 'js-origin-geoip' ).value;
		let dest = document.getElementById( 'js-destination-geoip' ).value;
		history.push( `/search/${origin}/${dest}` );
	};

	return (
		<div>
			<div
				// style={
				// 	props.showBg
				// 		? props.bg
				// 			? { backgroundImage: `url('${props.bg}')` }
				// 			: {
				// 					backgroundImage: `url('${dashboard}/background')`,
				// 			  }
				// 		: null
				// }
				className={`toolbar search-page${props.showBg ? ' bg' : ''}${props.darkMode ? ' dark' : ''
					}`}
			>
				<BingMap id={'map'} options={{ zoom: 11 }} />
				<FindDirectionsBox onExit={handleExitClick} onSearch={handleSearchSubmit} />
				{/* <Link to="/" onClick={handleExitClick} className="exit">
					&times;
				</Link> */}
			</div>
		</div>
	);
}

const mapStateToProps = ( state ) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
		preferences: state.preferences.init,
	};
};

export default connect( mapStateToProps, {
	setPreferencesInit,
	resetTopBarInteractions,
} )( Search );
