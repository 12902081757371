import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Icon } from 'react-icons-kit';
import { addFavorite } from '../../actions/AppActions';

import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import { heart } from 'react-icons-kit/fa/heart';
import { print } from 'react-icons-kit/entypo/print';

function DetailItem( { currentRoute, origin, destination, favorites, addFavorite } ) {
	const [isFavorite, setIsFavorite] = useState( false );

	useEffect( () => {
		favorites.forEach( ( fav ) => {
			if(
				fav.from.direction === origin.direction &&
				fav.to.direction === destination.direction
			) {
				setIsFavorite( true );
			}
		} );
	}, [] );

	var hanldeFavoriteClick = function( route ) {
		setIsFavorite( !isFavorite );
		addFavorite( {
			from: { points: origin.point, direction: origin.direction },
			to: { points: destination.point, direction: destination.direction },
		} );
	};

	return (
		<div className="details">
			<div className="header">
				<h4>
					{Math.round( currentRoute.travelDuration / 60 )} min{' '}
					<small>({currentRoute.travelDistance.toFixed( 1 )} km)</small>
				</h4>
				<p>{currentRoute.description}</p>
				<div className="actions">
					{/* <div
						onClick={() => {
							hanldeFavoriteClick(currentRoute);
						}}
						className={isFavorite ? 'favorite in' : 'favorite'}
					>
						<Icon size="20" icon={heart} />
					</div> */}
					<div
						onClick={() => {
							window.print();
						}}
						className="print"
					>
						<Icon size="20" icon={print} />
					</div>
				</div>
			</div>
			<p className="title">
				<strong>{origin.city}</strong>
				<small> {origin.direction}</small>
			</p>
			<div className="scroll">
				<ul>
					{currentRoute.itineraryItems.map( ( step, index ) => (
						<li key={index}>
							<Icon size="22" icon={chevronRight} />
							<p>
								{step.instruction.text}
								{step.travelDuration && (
									<small>
										{Math.round( step.travelDuration / 60 )} min (
										{step.travelDistance.toFixed( 1 )} km)
									</small>
								)}
							</p>
						</li>
					) )}
				</ul>
				<p className="title">
					<strong>{destination.city}</strong>
					<small> {destination.direction}</small>
				</p>
			</div>
		</div>
	);
}
const mapStateToProps = ( state ) => {
	return {
		favorites: state.app.favorites,
	};
};

export default connect( mapStateToProps, {
	addFavorite,
} )( DetailItem );
