import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { dashboard } from '../../util';

import './style.scss';

function Ad() {
	const [uid] = useState( localStorage.getItem( 'uid' ) );
	const [time] = useState( ( new Date ).getTime() );
	const topbarstate = useSelector( ( state ) => {
		return !!state.topBar ? state.topBar.collapsed : '';
	} );

	return (<a href={`${dashboard}/link?u=${uid}`} target="_blank" className={`ad`}>
		<img src={`${dashboard}/ad?v=${time}&u=${uid}&topbar=${topbarstate ? 'collapsed' : 'displayed'}`} />
	</a>);
}

export default Ad;
