import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Form from '../../components/Form';
import Ad from '../../components/Ad';
import ShortCutsList from '../../components/ShortCutsList';
import { selectCategory } from '../../actions/AppActions';
import { checkTopBarSettings } from '../../actions/TopBarActions';

import moment from 'moment';
import { dashboard } from '../../util';

import './style.scss';

function Home({ checkTopBarSettings, selectCategory,darkMode, ...props }) {
	useEffect(() => {
		selectCategory(null);
		checkTopBarSettings();
	}, []);

	let salute = 'Evening';
	const now = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
	const hour = parseInt(now.format('H'));
	if (hour >= 17 && hour < 21) {
		salute = 'Evening';
	} else if (hour >= 4 && hour < 12) {
		salute = 'Morning';
	} else if (hour >= 12 && hour < 17) {
		salute = 'Afternoon';
	}

	return (
		<div
			// style={
			// 	props.showBg
			// 		? props.bg
			// 			? { backgroundImage: `url('${props.bg}')` }
			// 			: { backgroundImage: `url('${dashboard}/background')` }
			// 		: null
			// }
			className={`toolbar ${props.showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}`}
		>
			<section className="main-content">
				<h1 className="text-center">Good {salute}</h1>
				<Form target="_blank" placeholder={'Search or type a URL'} />
				<Ad />
				<ShortCutsList />
			</section>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	checkTopBarSettings
})(Home);
