import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { androidCar } from 'react-icons-kit/ionicons/androidCar';
import axios from 'axios';
import { geocode } from '../tripod-components/util/bing';
import loadingGif from '../../images/loading.gif';
import { arrowLeft } from 'react-icons-kit/feather/arrowLeft';

import DetailItem from './DetailItem';

function FindDirectionsBoxResults(props) {
	let { from, to } = useParams();
	const [bingKey] = useState('AshH7R2MJYwFD5t64YhV5DAT-ttMsPhNoIPQYZfpB38kUDgMqXWcMXyeMnTj3O16');
	const [routes, setRoutes] = useState([]);
	const [currentRoute, setCurrentRoute] = useState(false);
	const [origin, setOrigin] = useState(false);
	const [destination, setDestination] = useState(false);

	useEffect(() => {
		geocode(from, bingKey).then((origin) => {
			geocode(to, bingKey).then((destination) => {
				setTimeout(() => {
					handleSearch(origin, destination);
				}, 500);
			});
		});
	}, []);

	const ShowRouteOnMap = (map, from, to) => {
		if (window.Microsoft) {
			window.Microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
				let directions_manager = new window.Microsoft.Maps.Directions.DirectionsManager(
					map
				);

				directions_manager.addWaypoint(
					new window.Microsoft.Maps.Directions.Waypoint({
						address: from,
					})
				);

				directions_manager.addWaypoint(
					new window.Microsoft.Maps.Directions.Waypoint({
						address: to,
					})
				);
				directions_manager.calculateDirections();
			});
		}
	};

	const handleSearch = (origin, destination) => {
		setOrigin(origin);
		setDestination(destination);
		axios
			.get('https://dev.virtualearth.net/REST/V1/Routes/Driving', {
				params: {
					o: 'json',
					'wp.0': origin.point.join(','),
					'wp.1': destination.point.join(','),
					avoid: 'minimizeTolls',
					maxSolns: '3',
					key: bingKey,
				},
			})
			.then((results) => {
				setRoutes(results.data.resourceSets[0].resources[0].routeLegs);
			});
	};

	if (routes.length === 0) {
		return (
			<div className="find-directions-box">
				<div className="text-center">
					<img src={loadingGif} width={200} />
				</div>
			</div>
		);
	}

	return (
		<div className="find-directions-box">
			<div
				onClick={() => {
					if (currentRoute) setCurrentRoute(false);
					else window.history.back();
				}}
				className="back"
			>
				<Icon size="30" icon={arrowLeft} />
			</div>
			<div className="routes">
				<div className="location">
					<p>
						<strong>From: </strong>
						{from}
					</p>
					<p>
						<strong>To: </strong>
						{to}
					</p>
				</div>
				{!currentRoute &&
					routes.map((route, index) => (
						<div
							key={index}
							className="route d-flex"
							onClick={() => {
								setCurrentRoute(route);
								ShowRouteOnMap(window.maps['#map'], from, to);
							}}
						>
							<div className="icon">
								<Icon size="30" icon={androidCar} />
							</div>
							<div className="title">
								<h4>{route.description}</h4>
								<p>Details</p>
							</div>
							<div className="extra">
								<p>{Math.round(route.travelDuration / 60)} min</p>
								<p>
									<small>{route.travelDistance.toFixed(1)} km</small>
								</p>
							</div>
						</div>
					))}

				{currentRoute && (
					<DetailItem
						currentRoute={currentRoute}
						origin={{ ...origin, direction: from }}
						destination={{ ...destination, direction: to }}
					/>
				)}
			</div>
		</div>
	);
}

export default FindDirectionsBoxResults;
