import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import './style.scss';

function Todo(props) {
	return (
		<li className="todo-item-li">
			<input
				checked={!props.todo.open}
				id={`todo-item-${props.index}`}
				type="checkbox"
				onChange={props.onChangeState}
			/>
			<label
				className="checkbox-switch"
				htmlFor={`todo-item-${props.index}`}
			></label>
			<TextareaAutosize
				autoFocus
				rows={1}
				autosize=""
				value={props.todo.text}
				onChange={props.onChangeText}
			></TextareaAutosize>
			<button onClick={props.onDelete} className="remove-todo">
				<i>x</i>
			</button>
		</li>
	);
}
function TodoList({
	items,
	onDeleteItem,
	onChangeItemState,
	onChangeItemText
}) {
	return items.map((item, index) => (
		<Todo
			onDelete={() => {
				onDeleteItem(item.id);
			}}
			onChangeState={() => {
				onChangeItemState(item.id);
			}}
			onChangeText={e => {
				onChangeItemText(item.id, e.target.value);
			}}
			key={index}
			index={index}
			todo={item}
		/>
	));
}

export default TodoList;
