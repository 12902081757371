import React from 'react';

import Checkbox from '../CheckboxItem';
import WeatherOptions from '../Settings/Weather';

import './style.scss';

function SettingItem({ name, onCheckBoxChange, isChecked }) {
	return (
		<div className="setting-item clearfix">
			<label>
				{name}
				<Checkbox onChange={onCheckBoxChange} checked={isChecked} />
			</label>
			{name === 'Weather' && <WeatherOptions />}
		</div>
	);
}

export default SettingItem;
