import React, { useState } from 'react';

import { Icon } from 'react-icons-kit';
import { heart } from 'react-icons-kit/fa/heart';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { addFavorite } from '../../actions/AppActions';

function Listing({ object, addFavorite }) {
	const [isFavorite, setIsFavorite] = useState(true);
	const history = useHistory();

	var hanldeFavoriteClick = function () {
		setIsFavorite(!isFavorite);
		addFavorite({
			from: { points: object.from.points, direction: object.from.direction },
			to: { points: object.to.points, direction: object.to.direction },
		});
	};

	const handleSearch = (e) => {
		e.preventDefault();
		history.push(`/search/${object.from.direction}/${object.to.direction}`);
	};

	console.log(object);

	return (
		<div className="listing">
			<iframe
				src={`/map.html?lat=${object.from.points[0]}&lng=${object.from.points[1]}`}
				frameBorder="0"
			></iframe>
			<a onClick={hanldeFavoriteClick} className={isFavorite ? 'favorite in' : 'favorite'}>
				<Icon icon={heart} />
			</a>
			<h4 className="mb-1">
				<a target="_blank" href="#" onClick={handleSearch}>
					<strong>From:</strong> {object.from.direction}
				</a>
			</h4>
			<h4 className="mb-1">
				<a target="_blank" href="#" onClick={handleSearch}>
					<strong>To:</strong> {object.to.direction}
				</a>
			</h4>
			<a href="#" onClick={handleSearch}>
				View Directions
			</a>
		</div>
	);
}

export default connect(null, { addFavorite })(Listing);
